<style lang='scss' scoped>
    .total {
        margin: 0 auto;
        position: relative;
        width: 965px;
        background-color: #fff;
        padding: 0 20px 20px 20px;
    }

    .add_location {
        position: relative;
        text-align: left;
        width: 100%;
        height: 30px;
        .msgSpan{
            margin-left: 15px;
        }
        .span-gray{
          color: #999999;
        }
    }

    .addLocationSpan {
        font-size: 15px;
        color: black;
        margin-left: 15px;
    }

    .add_location  {
        margin-top: 20px;
        color: black;
    }

    .btn_add {
        display: inline-block;
        line-height: 30px;
        width: 120px;
        text-align: center;
        // height: 30px;
        border: 1px solid #ef2d2e;
        border-radius: 20px;
        background: white;
        font-size: 14px;
        color: #ef2d2e;
        cursor: pointer;
    }

    .view_yiying {
        position: absolute;
        top: 0;
        left: -235px;
        width: 1200px;
        height: 980px;
        margin: 0;
        background-color: rgba(193, 193, 193, 0.2);
    }

    .div_content {
        margin-top: 20px;
        width: 100%;
        position: relative;
        text-align: left;
    }

    .item {
        padding: 26px 8px 26px 8px;
        list-style: none;
        border: 1px solid #DDDDDD;
        margin-bottom: 10px;
    }

    .table {
      .span_address{
        width: 600px;
        display: -webkit-box;
        overflow: hidden;
        white-space: normal !important;
        text-overflow: ellipsis;
        word-wrap: break-word;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical
      }
        width: 900px;
    }

    .item tr td:first-child {
        width: 80px;
        text-align: right;
        color: #999999;
        font-size: 14px;
    }

    .item tr td {
        color: #000000;
        font-size: 14px;
    }

    .item tr:last-child td:last-child {
        text-align: right;
    }

    .btn_default {
        border: none;
        background: #fff;
        font-size: 14px;
        color: black;
        cursor: pointer;
    }

    .set_defalut {
        margin-right: 32px;
    }

    .is_default {
        display: inline-block;
        line-height: 25px;
        width: 88px;
        height: 28px;
        text-align: center;
        border: 1px solid #ee2e2e;
        border-radius: 15px;
        color: #ee2e2e;
        margin-left: 19px;
        margin-right: 19px;
    }

    .edi {
        margin-right: 15px;
    }

    .mark {
        position: fixed;
        left: 0;
        top: 0;
        opacity: .5;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 998;
        pointer-events: none; //不能操作
    }

    .pop_add_local {
        position: absolute;
        left: 40%;
        top: 20%;
        text-align: center;
        margin-left: -200px;
        width: 516px;
        height: 407px;
        box-shadow: 0 0 32px 0 rgba(193, 193, 193, 0.72);
        padding-top: 26px;
        background: #ffffff;
        z-index: 1;
    }

    .province ul li a :hover {
        color: #ee2e2e;
    }

    .pop_add_local img {
        position: absolute;
        top: 3%;
        left: 100%;
        width: 24px;
        height: 24px;
        margin-left: -32px;
    }

    .pop_add_local p {
        font-size: 18px;
        color: #444444;
    }

    .pop_add_local li div {
        margin-top: 22px;
    }

    .pop_add_local li input {
        padding-left: 25px;
        width: 302px;
        height: 46px;
        line-height: 46px;
        background: none;
        border: none;
        outline: 1px solid #ccc; //边框不用border，用outline
    }

    .pop_add_local span {
        font-size: 14px;
        color: #999999;
    }

    button {
        outline: none;
    }

    .pop_add_local button {
        width: 90px;
        height: 30px;
        border-radius: 4px;

        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-top: 33px;
        cursor: pointer;
        color: white;
    }

    .div_hang {
        position: relative;
        background: gray;
        margin: 0 auto;
    }

    .chooseCity {
        position: absolute;
        top: 43%;
        left: 70%;
        margin-top: -150px;
        width: 390px;
        padding-bottom: 24px;
        background: #ffffff;
        padding-top: 17px;
        box-shadow: 0 0 32px 0 rgba(193, 193, 193, 0.72);
    }

    .chooseCity span {
        font-size: 15px;
        color: #444444;
    }

    .chooseCity a {
        color: #ee2e2e;
        font-size: 15px;
    }

    .chooseCity img {
        left: 100%;
        top: 3%;
        width: 24px;
        height: 24px;
        margin-left: -32px;
    }

    .province {
        margin: 0;
    }

    .province ul {
        padding: 0 0 0 24px;
    }

    .province ul li {
        float: left;
        margin-top: 12px;
        width: 70px;
        text-align: center;
        height: 18px;
    }

    .province ul li a {
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        color: #444444;
    }

    .pop_del_loca {
        position: absolute;
        left: 50%;
        top: 50%;
        text-align: center;
        margin-top: -130px;
        margin-left: -223px;
        width: 455px;
        height: 261px;
        z-index: 1;
        background-color: #ffffff;
        border: 1px solid #F2F2F2;
    }

    .pop_del_loca img {
        position: absolute;
        left: 100%;
        width: 32px;
        height: 32px;
        margin-left: -32px;
        margin-top: -36px;
    }

    .pop_del_loca p {
        font-size: 15px;
        color: #444444;
        width: 100%;
        height: 37px;
        line-height: 37px;
        padding-left: 12px;
    }

    .del_pop_title {
        background-color: #f7f7f7;
    }

    .del_pop_content {
        margin-top: 72px;
        text-align: center;
        background-color: #ffffff;;
    }

    .pop_del_loca button {
        width: 88px;
        height: 28px;
        border-radius: 6px;
        margin-top: 57px;
        color: white;
        cursor: pointer;
    }

    .btn_ok {
        background-color: #ee2e2e;
        border: 1px solid #ee2e2e;
        margin-right: 32px;
        margin-left: 28px;
    }

    .btn_no {
        background-color: #999999;
        border: 1px solid #999999;
    }

    .zZindex {
        z-index: 3000 !important;
    }
    .noDataBox {
      margin-top: 170px !important;
      img {
        margin-bottom: 20px;
      }
      .noData{
        font-size: 16px !important;
      }
    }
</style>
<template>

  <div class="total">
    <p class="myclass-title">
      <span class="title-text">地址管理</span>
      <span class="title-border"></span>
    </p>
    <p class="add_location">
      <span class="btn_add" @click="isShowAddDialog(1,null)">新增收货地址</span>
      <span class="msgSpan span-gray">您已创建了</span>
      <span v-cloak class="addLocationSpan" style="margin-left:0"> {{ allReadyHas }} </span>
      <span class="span-gray">个收货地址，最多可创建</span>
      <span v-cloak class="addLocationSpan" style="margin-left: 0"> {{ maxNum }} </span>
      <span class="span-gray">个</span>
    </p>
    <!--删除地址弹窗-->
    <div v-show="isShowDelPop" class="pop_del_loca">
      <p class="del_pop_title" style="text-align: left">删除收货地址</p>

      <img src="../../../assets/image/close.gif" alt="close" @click="isShowDelDialog(0,null)">
      <p class="del_pop_content">
        你确定要删除该收货地址吗？
      </p>
      <button class="btn_ok" @click="btn_ok()">确认</button>
      <button class="btn_no" @click="isShowDelDialog(0,null)">取消</button>
    </div>
    <!--添加地址弹窗-->
    <div v-show="isShowAddPop" class="pop_add_local">
      <p>{{ dialogTitle }}收货地址</p>
      <img src="../../../assets/image/white_close.png" alt="close" @click="isShowAddDialog(0,null)">
      <ul>
        <li>
          <div>
            <span> *收货人：</span>
            <label>
              <input
                v-model="paramAdd_local_msg.receiver"
                style="margin-left: 12px"
                type="text"
                placeholder="请填写收货人"
              >
            </label>
          </div>
          <div>
            <span>*联系方式：</span>
            <label>
              <input
                v-model="paramAdd_local_msg.mobile"
                type="text"
                placeholder="请填写联系方式"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
              >
            </label>
          </div>
          <div id="div_hang">
            <span>*所在地区：</span>
            <label>
              <input
                v-model="provinceAndCityName"
                type="text"
                placeholder="请填写所在地区"
                readonly="readonly"
                @click="isShowCityDialog(1)"
              >
            </label>
          </div>
          <!--地选择城市弹窗-->
          <div v-show="isShowCityPop" id="pop_province" class="chooseCity">
            <p>
              <span>所在地区</span>&nbsp;
              <a v-if="this.cityLevel>1" href="javascript:void (0)" @click="backProvince()">[返回上一级]</a>
              <img src="../../../assets/image/white_close.png" alt="close" @click="isShowCityDialog(0)">
            </p>
            <div class="province" style="margin: 0">
              <ul>
                <li
                  v-for="(item,index) in provinceData"
                  v-if="cityLevel===1"
                  :key="index"
                >
                  <a class="pop_local_name" @click="showCity(item.id,item.cname)">{{ item.cname }}</a>
                </li>

                <li
                  v-for="item1 in cityData"
                  v-if="cityLevel===2"
                >
                  <a
                    class="pop_local_name"
                    @click="showCity(item1.id,item1.cname)"
                  >{{ item1.cname }}</a>
                </li>

                <li
                  v-for="item2 in areaData"
                  v-if="cityLevel===3"
                >
                  <a
                    class="pop_local_name"
                    @click="showCity(item2.id,item2.cname)"
                  >{{ item2.cname }}</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <span>*详细地址：</span>
            <label>
              <input
                v-model="paramAdd_local_msg.detail_address"
                type="text"
                placeholder="请填写详细地址"
              >
            </label>
          </div>
        </li>
      </ul>
      <button @click="submitLocation()">保存</button>
    </div>

    <!--列表内容-->
    <div class="div_content">
      <ul>
        <li
          v-for="item in this.resultLocalData.result.list"
          v-show="show_address_list"
          :key="item.id"
          class="item"
        >
          <table class="table">
            <tr>
              <td>收货人：</td>
              <td colspan="2">{{ item.receiver }}</td>
            </tr>
            <tr>
              <td>联系方式：</td>
              <td colspan="2">{{ item.mobile }}</td>
            </tr>
            <tr>
              <td>所在地区：
              </td><td colspan="2">{{ item.province }}&nbsp;&nbsp;{{ item.city }} {{ item.county }}</td>
            </tr>
            <tr>
              <td>详细地址：</td>
              <td><span class="span_address">{{ item.detail_address }}</span></td>
              <td>

                <span
                  v-if="item.is_default === 1"
                  class="btn_default set_defalut is_default"
                  @click="setDefaultLocal(item.id,true)"
                >默认地址
                </span>
                <span
                  v-else
                  class="btn_default set_defalut"
                  @click="setDefaultLocal(item.id,false)"
                >设置默认</span>
                <span class="btn_default edi" @click="getEdiLocalInfo(item.id)">编辑</span>
                <span class="btn_default" @click="isShowDelDialog(1,item.id)">删除</span>
              </td>
            </tr>
          </table>
        </li>
      </ul>

    </div>
    <div v-show="resultLocalData.result.count===0" class=" noDataBox">
      <img src="@/assets/image/kong.png" alt="">
      <span class="noData">您还没有收货地址，快来添加吧</span>
    </div>

    <div v-show="isShowYinYing" class="view_yiying" />
  </div>
</template>

<script>
import {
  editAddress,
  getNeedEdiLocalInfo,
  getUserAddressList,
  getUserAddressListThree,
  setDefaultAndDelete
} from '../../../api/mine'

export default {
  data() {
    return {
      dialogTitle: '新增',
      // 禁止重复点击
      canClick: true,
      allReadyHas: 0,
      maxNum: 5,
      needDeleteId: '',
      provinceAndCityName: '',
      provinceName: '',
      cityName: '',
      areaName: '',
      isShowAddPop: false,
      isShowCityPop: false,
      isShowDelPop: false,
      isShowYinYing: false,
      show_address_list: false,
      ediPosition: 0,
      cityLevel: 0,
      // [设置默认/删除]入参
      paramDefaultOrDel: {
        id: '',
        // 1：默认 0取消默认
        is_default: '',
        // 删除传1
        status: ''
      },
      // 获取编辑信息入参
      paramGetEdiInfo: {
        id: ''
      },
      // 保存添加信息入参
      paramAdd_local_msg: {
        id: '',
        receiver: '',
        mobile: '',
        province_id: '',
        city_id: '',
        county_id: '',
        detail_address: ''
      },
      params_citys: {
        parent_id: ''
      },

      needEdiMsg: {
        id: '', // 类型：Number  必有字段  备注：无
        province_id: '',
        city_id: '',
        county_id: '',
        receiver: '', // 类型：String  必有字段  备注：收货人
        mobile: '', // 类型：String  必有字段  备注：手机号
        is_default: '', // 类型：Number  必有字段  备注：是否是默认地址 1是 0 否
        province: '', // 类型：String  必有字段  备注：无
        city: '', // 类型：String  必有字段  备注：无
        detail_address: '',
        county: ''

      },
      resultLocalData: {
        errNo: '',
        msg: '',
        result: {
          count: 1,
          list: [{
            id: '',
            receiver: '',
            mobile: '',
            is_default: '',
            province_id: '',
            city_id: '',
            county_id: '',
            detail_address: '',
            province: '',
            county: '',
            city: ''
          }]
        }
      },
      locationData: [],

      resultProvinceCity: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }],
      provinceData: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }],
      cityData: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }],
      areaData: [{
        id: '',
        parent_id: '',
        cname: '',
        level: ''
      }]

    }
  },

  created() {
    this.getLocationData()
    this.getProvinceCity()
  },

  mounted() {
  },

  methods: {
    // 弹窗提示
    successTip(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    warningTip(msg) {
      this.$message({
        message: msg,
        type: 'warning'
      })
    },
    normalTip(msg) {
      this.$message(msg)
    },
    errorTip(msg) {
      this.$message({
        message: msg,
        type: 'error'
      })
    },
    /* 获取地址列表*/
    getLocationData() {
      getUserAddressList().then((res) => {
        if (parseInt(res.errNo) === 0) {
          this.resultLocalData.msg = res.msg
          this.resultLocalData.errNo = res.errNo
          this.allReadyHas = res.result.count
          this.resultLocalData.result.count = res.result.count
          this.resultLocalData.result.list = res.result.list
          if (this.resultLocalData.result.list.length >= 0) {
            this.show_address_list = true
          } else {
            this.show_address_list = false
            this.resultLocalData.result.count = 0
          }
        } else {
          this.show_address_list = false
        }
      })
    },
    /* 获取需要编辑的信息*/
    getEdiLocalInfo(id) {
      this.paramGetEdiInfo.id = id
      this.paramAdd_local_msg.id = id
      // 获取需要编辑的信息
      getNeedEdiLocalInfo(this.paramGetEdiInfo).then((res) => {
        if (parseInt(res.errNo) === 0) {
          this.needEdiMsg = res.result
          // 显示编辑弹窗
          this.isShowAddDialog(1, this.needEdiMsg)
        }
      })
    },
    /* 添加/编辑信息*/
    submitLocation() {
      if (!this.paramAdd_local_msg.receiver) {
        this.warningTip('收货人不能为空')
        return
      }
      if (!this.paramAdd_local_msg.mobile) {
        this.warningTip('联系方式不能为空')
        return
      } else if (!(/^1\d{10}$/.test(this.paramAdd_local_msg.mobile))) {
        this.warningTip('请输入有效的手机号')
        return
      }
      if (!this.paramAdd_local_msg.province_id) {
        this.warningTip('所在地区不能为空')
        return
      } else if (!this.paramAdd_local_msg.city_id) {
        this.warningTip('所在地区市区不能为空')
        return
      }
      if (!this.paramAdd_local_msg.detail_address) {
        this.warningTip('详细地址不能为空')
        return
      }
      this.isShowYinYing = false
      this.isShowAddPop = false
      this.isShowCityPop = false
      editAddress(this.paramAdd_local_msg).then((res) => {
        if (parseInt(res.errNo) === 0) {
          this.successTip(this.dialogTitle + '地址成功')
          this.getLocationData()
        }
      })
      /* 清空提交信息*/
      this.paramAdd_local_msg.id = ''
      this.paramAdd_local_msg.receiver = ''
      this.paramAdd_local_msg.mobile = ''
      this.paramAdd_local_msg.province_id = ''
      this.paramAdd_local_msg.city_id = ''
      this.paramAdd_local_msg.county_id = ''
      this.paramAdd_local_msg.detail_address = ''
      this.provinceAndCityName = ''
    },
    /* 设置默认地址*/
    setDefaultLocal(id, isDefault) {
      if (isDefault) {
        this.warningTip('已经是默认地址')
      } else {
        this.paramDefaultOrDel.id = id
        this.paramDefaultOrDel.is_default = '1'
        this.paramDefaultOrDel.status = '1'
        setDefaultAndDelete(this.paramDefaultOrDel).then((res) => {
          if (parseInt(res.errNo) === 0) {
            this.successTip('设置默认地址成功')
            this.getLocationData()
          } else {
            this.errorTip('设置默认地址失败')
          }
        })
        // 完成置空
        this.paramDefaultOrDel.id = ''
        this.paramDefaultOrDel.is_default = ''
      }
    },
    /* 删除地址*/
    btn_ok() {
      this.paramDefaultOrDel.id = this.needDeleteId
      this.paramDefaultOrDel.status = '0'
      this.isShowDelPop = false
      this.isShowYinYing = false
      setDefaultAndDelete(this.paramDefaultOrDel).then((res) => {
        if (parseInt(res.errNo) === 0) {
          this.successTip('删除地址成功')
          this.getLocationData()
        } else {
          this.errorTip('删除地址失败')
        }
      })
      // 重置状态
      this.needDeleteId = ''
    },
    /* 控制添加地址弹窗*/
    isShowAddDialog(isShow, needEdiMag) {
      if (this.allReadyHas < 5 || needEdiMag != null || isShow === 0) {
        if (needEdiMag != null) {
          this.dialogTitle = '编辑'
          this.paramAdd_local_msg.receiver = needEdiMag.receiver
          this.paramAdd_local_msg.mobile = needEdiMag.mobile
          this.paramAdd_local_msg.province_id = needEdiMag.province_id
          this.paramAdd_local_msg.city_id = needEdiMag.city_id
          this.paramAdd_local_msg.county_id = needEdiMag.county_id
          this.paramAdd_local_msg.detail_address = needEdiMag.detail_address
          if (needEdiMag.province != null) {
            this.provinceAndCityName = needEdiMag.province
            if (needEdiMag.city != null) {
              this.provinceAndCityName = needEdiMag.province + ' ' + needEdiMag.city
              if (needEdiMag.county != null) {
                this.provinceAndCityName = needEdiMag.province + ' ' + needEdiMag.city + ' ' + needEdiMag.county
              }
            }
          }
        } else {
          this.dialogTitle = '新增'
          this.paramAdd_local_msg.id = ''
        }
        if (isShow === 1) {
          this.isShowAddPop = true
          this.isShowYinYing = true
        } else {
          this.isShowCityPop = false
          this.isShowAddPop = false
          this.isShowYinYing = false
          /* 清空提交信息*/
          this.paramAdd_local_msg.id = ''
          this.paramAdd_local_msg.receiver = ''
          this.paramAdd_local_msg.mobile = ''
          this.paramAdd_local_msg.province_id = ''
          this.paramAdd_local_msg.city_id = ''
          this.paramAdd_local_msg.county_id = ''
          this.paramAdd_local_msg.detail_address = ''
          this.provinceAndCityName = ''
        }
      } else {
        this.warningTip('最多添加五个收货地址')
      }
    },
    /* 控制删除弹窗*/
    isShowDelDialog(tag, delId) {
      if (tag === 0) {
        this.needDeleteId = ''
        this.isShowDelPop = false
        this.isShowYinYing = false
      } else {
        this.needDeleteId = delId
        this.isShowDelPop = true
        this.isShowYinYing = true
      }
    },
    /* 控制城市弹窗*/
    isShowCityDialog(isShow) {
      if (isShow !== 1) {
        this.isShowCityPop = false
        this.isShowYinYing = false
        this.cityLevel = 1
      } else {
        this.isShowCityPop = true
        this.isShowYinYing = true
      }
    },
    /* 显示城市列表*/
    showCity(pid, cname) {
      if (this.cityLevel > 2) {
        // 选择完毕
        this.paramAdd_local_msg.county_id = pid
        this.isShowCityPop = false
        this.isShowYinYing = false
        this.areaName = cname
        this.provinceAndCityName = this.provinceName + '  ' + this.cityName + ' ' + this.areaName
        this.cityLevel = 1
      } else {
        this.params_citys.parent_id = pid
        if (this.cityLevel === 1) {
          this.paramAdd_local_msg.province_id = pid
          this.paramAdd_local_msg.city_id = ''
          this.paramAdd_local_msg.county_id = ''
          this.provinceName = cname
          this.provinceAndCityName = this.provinceName
        } else {
          this.cityName = cname
          this.paramAdd_local_msg.city_id = pid
          this.paramAdd_local_msg.county_id = ''
          this.provinceAndCityName = this.provinceName + ' ' + this.cityName
          this.params_citys.parent_id = pid
        }

        this.getProvinceCity()
      }
    },
    /* 显示省市列表*/
    backProvince() {
      this.cityLevel--
    },

    getProvinceCity() {
      if (this.canClick) {
        this.canClick = false
        getUserAddressListThree(this.params_citys).then((res) => {
          this.canClick = true
          if (parseInt(res.errNo) === 0) {
            if (this.cityLevel === 0) {
              this.provinceData = res.result
            } else if (this.cityLevel === 1) {
              this.isShowCityPop = true
              this.isShowYinYing = true
              this.cityData = res.result
            } else {
              this.isShowCityPop = true
              this.isShowYinYing = true
              this.areaData = res.result
              if (this.areaData.length === 0) {
                this.isShowCityPop = false
                this.cityLevel = 0
              }
            }
            this.cityLevel++
          }
        })
      }
    }
  }
}

</script>
